import styled from 'styled-components'
import { SCREEN } from 'styles/screens'

export const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px;

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
