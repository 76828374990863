import * as React from 'react'
import styled from 'styled-components'

import Layout from 'layouts/main'
import { SCREEN } from 'styles/screens'
import { FixedContainer } from '../components/styled/containers'
import { getTranslation } from '../data/dictionary'
import RenderHtml from '../components/shared/renderHtml'

const Container = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
  iframe {
    width: 100%;
    height: 700px;
    overflow: hidden;
    border: none;
  }
`
const Page = ({ pageContext }) => {
  const { page } = pageContext

  return (
    <Layout title={page.title} ogImage={page.key === 'critical-thinking'? 'https://d34rq7yialevbd.cloudfront.net/uploads/906c1e19-fbcf-4b41-9a52-994dccad2253':undefined} showFooter={true}>
      <Container>
        <FixedContainer>
          <RenderHtml html={page.content} tag={'div'}/>
        </FixedContainer>
      </Container>
    </Layout>
  )
}

export default Page
